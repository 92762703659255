import { useEffect } from "react";
import { Outlet, useLoaderData, useRouteLoaderData, type LoaderFunctionArgs } from "react-router";
import { getAppToken } from "~/.server/token";
import { invariant } from "~/lib/utils";
import { getFeedUrl } from "~/pages/episode/.server/feed";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const token = await getAppToken(request, { user: true });
  if (!token) {
    return { user: null };
  }
  const { user } = token;
  const feedUrl = await getFeedUrl(user.userId);

  return {
    user,
    feedUrl,
  };
};

/**
 * @returns Current user or null or null if not authenticated
 */
export function useUser() {
  return useRouteLoaderData<typeof loader>("with-user")?.user ?? null;
}

/**
 * @returns Current user or crashes if not authenticated. Should be used in pages/components that are only rendered if
 *          authenticated.
 */
export function requireUser() {
  const user = useRouteLoaderData<typeof loader>("with-user")?.user;
  invariant(user, "No user");
  return user;
}

export function useFeedUrl() {
  const feedUrl = useRouteLoaderData<typeof loader>("with-user")?.feedUrl;
  invariant(feedUrl, "No feedUrl");
  return feedUrl;
}

export function userFromMatches(matches: { id: string; data: unknown }[]) {
  const data = matches.find((m) => m.id === "with-user")?.data as Awaited<ReturnType<typeof loader>>;
  return data?.user;
}

declare global {
  interface Window {
    syncTheme: () => void;
  }
}

export default function WithUser() {
  const { user } = useLoaderData<typeof loader>();
  const theme = user?.prefs.theme ?? "system";
  useEffect(() => {
    // store theme in localStorage so that on subsequent visits it can be set immediately in root layout which avoids flashing
    if (theme === "system") {
      localStorage.removeItem("theme");
    } else {
      localStorage.theme = theme;
    }
    window.syncTheme(); // Defined in theme.js
  }, [theme]);
  return <Outlet />;
}
